import {Link} from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import useStations from '../utils/hooks/useStations'

const PartnersPage = () => {
  const {station} = useStations()
  return (
    <Layout station={station}>
      <Seo title="Partners" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>Partners</h1>

            <p className="intro">
              NEWA partners understand that the value of weather data is greater
              when shared.
            </p>

            <p>
              Fifteen land grant universities and grower associations partner
              together to build the NEWA network across 15 states. Created by
              the New York State Integrated Pest Management Program at Cornell
              University, NEWA is a partnership with the Northeast Regional
              Climate Center. NEWA’s weather station hardware partners are
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset Data Loggers
              </Link>{' '}
              and <Link to="https://kestrelmet.com">KestrelMet</Link>. Our
              funding partners are many, since our founding in 1995 and across
              our partners states; we acknowledge them on this page.
            </p>

            <h2>NEWA in New York State</h2>
            <div className="p-4 mb-10 lg:p-6 bg-light-200">
              <p>
                The New York State Coordinator is listed here. Contact this
                person with questions about NEWA tools and resources in New York
                or find your NEWA State Coordinator in the listing below.
              </p>
            </div>

            <p>
              Growers in NEWA Partner States can link their own Onset Data
              Logger or <Link to="https://kestrelmet.com">KestrelMet</Link>
              weather station to the NEWA network at no additional charge. Visit
              our <Link to="/buy-a-weather-station">
                Buy a Weather Station
              </Link>{' '}
              to learn more.
            </p>

            <p>
              Want your state to join NEWA? Visit our{' '}
              <Link to="/become-partner">Become a Partner</Link> page to learn
              more about state-level membership.
            </p>
            <hr></hr>

            <h2>NEWA State Coordinators</h2>
            <p>Find your NEWA state coordinator for additional information:</p>

            <div className="mt-6 text-base sm:grid sm:grid-cols-2 sm:gap-4 xl:grid-cols-3">
              <div>
                <h3>Connecticut</h3>
                <p>
                  <strong className="text-primary-800">Evan Lentz</strong>
                  <br></br>
                  University of Connecticut<br></br>
                  860-486-6449<br></br>
                  <a href="mailto:evan.lentz@uconn.edu?subject=NEWA">
                  evan.lentz@uconn.edu
                  </a>
                </p>
              </div>
              <div>
                <h3>Delaware</h3>
                <p>
                  <strong className="text-primary-800">David Owens</strong>
                  <br></br>
                  University of Delaware<br></br>
                  302-856-7303<br></br>
                  <a href="mailto:owensd@udel.edu?subject=NEWA">
                    owensd@udel.edu
                  </a>
                </p>
              </div>
              <div>
                <h3>Georgia</h3>
                <p>
                  <strong className="text-primary-800">Ashley Hoppers</strong>
                  <br></br>
                  University of Georgia<br></br>
                  706-632-3061<br></br>
                  <a href="mailto:aahoppers@uga.edu?subject=NEWA">
                    aahoppers@uga.edu
                  </a>
                </p>
              </div>
              <div>
                <h3>Illinois</h3>
                <p>
                  <strong className="text-primary-800">Elizabeth Wahle</strong>
                  <br></br>
                  University of Illinois at Urbana-Champagne<br></br>
                  618-344-4230<br></br>
                  <a href="mailto:wahle@illinois.edu?subject=NEWA">
                    wahle@illinois.edu
                  </a>
                </p>
              </div>
              <div>
                <h3>Indiana</h3>
                <p>
                  <strong className="text-primary-800">Janna Beckerman</strong>
                  <br></br>
                  Purdue University<br></br>
                  765-494-4628<br></br>
                  <a href="mailto:jbeckerm@purdue.edu?subject=NEWA">
                    jbeckerm@purdue.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>Kentucky</h3>
                <p>
                  <strong className="text-primary-800">Brent Arnoldussen</strong>
                  <br></br>
                  University of Kentucky<br></br>
                  (859) 257-4721<br></br>
                  <a href="mailto:bar244@uky.edu?subject=NEWA">
                    bar244@uky.ed
                  </a>
                </p>
              </div>

              <div>
                <h3>Massachusetts</h3>
                <p>
                  <strong className="text-primary-800">Jon Clements</strong>
                  <br></br>
                  University of Massachusetts<br></br>
                  413-478-7219<br></br>
                  <a href="mailto:clements@umext.umass.edu?subject=NEWA">
                    clements@umext.umass.edu
                  </a>
                </p>
              </div>
              <div>
                <h3>Maine</h3>
                <p>
                  <strong className="text-primary-800">Lily Calderwood</strong>
                  <br></br>
                  University of Maine<br></br>
                  207-581-2369<br></br>
                  <a href="mailto:lily.calderwood@maine.edu?subject=NEWA">
                    lily.calderwood@maine.edu
                  </a>
                </p>
                <p>
                  <strong className="text-primary-800">Sean Birkel</strong>
                  <br></br>
                  University of Maine<br></br>
                  207-581-2369<br></br>
                  <a href="mailto:birkel@maine.edu?subject=NEWA">
                    birkel@maine.edu
                  </a>
                </p>
              </div>
              <div>
                <h3>Michigan</h3>
                <p>
                  <strong className="text-primary-800">Keith Mason</strong>
                  <br></br>
                  Michigan State University<br></br>
                  517-432-6520<br></br>
                  <a href="mailto:eweather@msu.edu?subject=NEWA">
                    eweather@msu.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>Minnesota</h3>
                <p>
                  <strong className="text-primary-800">Vacant</strong>
                  <br></br>
                  Minnesota Apple Growers Association<br></br>
                  <a href="mailto:support@newa.zendesk.com?subject=NEWA">
                    support@newa.zendesk.com
                  </a>
                </p>
              </div>

              <div>
                <h3>New Hampshire</h3>
                <p>
                  <strong className="text-primary-800">Jeremy DeLisle</strong>
                  <br></br>
                  University of New Hampshire<br></br>
                  603-255-3592<br></br>
                  <a href="mailto:jeremy.delisle@unh.edu?subject=NEWA">
                    jeremy.delisle@unh.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>New Jersey</h3>
                <p>
                  <strong className="text-primary-800">Peter Oudemans</strong>
                  <br></br>
                  Rutgers, The State University<br></br>
                  609-726-1590<br></br>
                  <a href="mailto:oudemans@rutgers.edu?subject=NEWA">
                    oudemans@rutgers.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>New York</h3>
                <p>
                  <strong className="text-primary-800">Dan Olmstead</strong>
                  <br></br>
                  Cornell University<br></br>
                  315-787-2207<br></br>
                  <a href="mailto:dlo6@cornell.edu?subject=NEWA%20weather%20station%20inquiry">
                    dlo6@cornell.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>North Carolina</h3>
                <p>
                  <strong className="text-primary-800">Mike Parker</strong>
                  <br></br>
                  North Carolina State University<br></br>
                  919-515-1198<br></br>
                  <a href="mailto:mlp@ncsu.edu?subject=NEWA">mlp@ncsu.edu</a>
                </p>
              </div>

              <div>
                <h3>Ohio</h3>
                <p>
                  <strong className="text-primary-800">Melanie Ivey</strong>
                  <br></br>
                  The Ohio State University-OARDC<br></br>
                  330-263-3849<br></br>
                  <a href="mailto:ivey.14@osu.edu?subject=NEWA">
                    ivey.14@osu.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>Pennsylvania</h3>
                <p>
                  <strong className="text-primary-800">Kari Peter</strong>
                  <br></br>
                  Penn State University<br></br>
                  Biglerville, PA 17307<br></br>
                  717-778-4587<br></br>
                  <a href="mailto:kap22@psu.edu?subject=NEWA">kap22@psu.edu</a>
                </p>
              </div>

              <div>
                <h3>Utah</h3>
                <p>
                  <strong className="text-primary-800">Jimmy Larson</strong>
                  <br></br>
                  Utah State University<br></br>
                  435-797-2258<br></br>
                  <a href="mailto:j.larsonk@usu.edu?subject=NEWA">
                    j.larson@usu.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>Vermont</h3>
                <p>
                  <strong className="text-primary-800">Terence Bradshaw</strong>
                  <br></br>
                  University of Vermont<br></br>
                  802-922-2591<br></br>
                  <a href="mailto:tbradsha@uvm.edu?subject=NEWA">
                    tbradsha@uvm.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>Virginia</h3>
                <p>
                  <strong className="text-primary-800">Mizuho Nita</strong>
                  <br></br>
                  Virginia Tech<br></br>
                  540-869-2560 ext. 33<br></br>
                  <a href="mailto:nita24@vt.edu?subject=NEWA">nita24@vt.edu</a>
                </p>
              </div>

              <div>
                <h3>West Virginia</h3>
                <p>
                  <strong className="text-primary-800">MM Rahman</strong>
                  <br></br>
                  West Virginia University<br></br>
                  304-293-8838<br></br>
                  <a href="mailto:mm.Rahman@mail.wvu.edu?subject=NEWA">
                    mm.Rahman@mail.wvu.edu
                  </a>
                </p>
              </div>

              <div>
                <h3>Wisconsin</h3>
                <p>
                  <strong className="text-primary-800">Amaya Atucha</strong>
                  <br></br>
                  University of Wisconsin-Madison<br></br>
                  608-262-6452<br></br>
                  <a href="mailto:atucha@wisc.edu?subject=NEWA">
                    atucha@wisc.edu
                  </a>
                </p>
              </div>
            </div>

            <hr></hr>

            <h2>NEWA Funding Acknowledgements</h2>

            <p>
              Our funding partners have insured the success and continuation of
              NEWA. We acknowledge the contributions of all the farms that have
              purchased installed, and maintained a weather station. This
              support is priceless.
            </p>

            <ul>
              <li>Cornell Cooperative Extension</li>
              <li>Cornell University Agricultural Experiment Station</li>
              <li>iPiPE</li>
              <li>Garden State Wine Growers Association</li>
              <li>Lake Erie Region Grape Processors</li>
              <li>Michigan Cherry Committee</li>
              <li>Michigan Apple Committee</li>
              <li>Michigan Tree Fruit Commission</li>
              <li>Minnesota Apple Growers Association</li>
              <li>New York Apple Research and Development Program</li>
              <li>New York State Berry Growers Association</li>
              <li>New York State Department of Agriculture and Markets</li>
              <li>New York State Integrated Pest Management Program</li>
              <li>New York Farm Viability Institute</li>
              <li>New York Wine and Grape Foundation</li>
              <li>North Carolina Apple Growers Association</li>
              <li>Northeast SARE</li>
              <li>Northeastern IPM Center</li>
              <li>Northeast Center for Risk Management Education</li>
              <li>Natural Resources Conservation Service</li>
              <li>New York State Agricultural Experiment Station</li>
              <li>Pennsylvania Horticultural Society</li>
              <li>
                USDA National Institute of Food and Agriculture
                <ul>
                  <li>CPPM ARDP</li>
                  <li>CPPM EIP</li>
                  <li>Hatch</li>
                  <li>NEED NERA</li>
                  <li>Smith Lever Funds</li>
                  <li>Specialty Crops Block Grants</li>
                </ul>
              </li>
              <li>USDA Telecommunications</li>
              <li>US Forest Service Climate Center, Eastern Region</li>
              <li>
                University of Massachusetts Amherst Center for Agriculture,
                Food, and the Environment
              </li>
              <li>Virginia Wine Board</li>
              <li>Viticulture Consortium – East</li>
              <li>Wisconsin Apple Growers Association</li>
              <li>Wisconsin Grape Growers Association</li>
            </ul>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated 4 January 2022 by D. Olmstead</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{' '}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
            <hr></hr>
            <h2>NEWA Partners</h2>
            <ul>
              <li>
                <a
                  href="http://www.cornell.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cornell University
                </a>
              </li>
              <li>
                <a
                  href="http://www.msu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Michigan State University
                </a>
              </li>
              <li>
                <a
                  href="http://www.minnesotaapple.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Minnesota Apple Growers Assoc.
                </a>
              </li>
              <li>
                <a
                  href="http://www.ncapplegrowers.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  North Carolina Apple Growers Assoc.
                </a>
              </li>
              <li>
                <a
                  href="http://cfaes.osu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Ohio State University
                </a>
              </li>
              <li>
                <a
                  href="http://www.psu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pennsylvania State University
                </a>
              </li>
              <li>
                <a
                  href="http://purdue.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Purdue University
                </a>
              </li>
              <li>
                <a
                  href="http://www.rutgers.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rutgers University
                </a>
              </li>
              <li>
                <a
                  href="http://uconn.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Connecticut
                </a>
              </li>
              <li>
                <a
                  href="http://udel.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Delaware
                </a>
              </li>
              <li>
                <a
                  href="http://uga.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Georgia
                </a>
              </li>
              <li>
                <a
                  href="http://illinois.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Illinois
                </a>
              </li>
              <li>
                <a
                  href="http://maine.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Maine
                </a>
              </li>
              <li>
                <a
                  href="http://ag.umass.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Massachusetts
                </a>
              </li>
              <li>
                <a
                  href="http://www.unh.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of New Hampshire
                </a>
              </li>
              <li>
                <a
                  href="http://www.uvm.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Vermont
                </a>
              </li>
              <li>
                <a
                  href="https://www.wisc.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Wisconsin-Madison
                </a>
              </li>
              <li>
                <a
                  href="http://www.usu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Utah State University
                </a>
              </li>
              <li>
                <a
                  href="https://www.vt.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Virginia Tech
                </a>
              </li>
              <li>
                <a
                  href="https://www.extension.wvu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  West Virginia University Extension Service
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default PartnersPage
